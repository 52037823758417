/* module attributes */

export const HOTEL_ATTRIBUTE = 'data-tl-hotel-id';
export const ROOM_TYPE_ATTRIBUTE = 'data-tl-room-type';
export const ADULTS_ATTRIBUTE = 'data-tl-adults';
export const CURRENCY_ATTRIBUTE = 'data-tl-currency';
export const DATE_ATTRIBUTE = 'data-tl-date';
export const MIN_LOS_ATTRIBUTE = 'data-tl-minlos';
export const OFFER_CODE_ATTRIBUTE = 'data-tl-offer-code';
export const OFFER_NAME_ATTRIBUTE = 'data-tl-offer-name';
export const LINK_ATTRIBUTE = 'data-tl-link';
export const LINK_BOOK_URL_ATTRIBUTE = 'data-tl-link-book-url';
export const RESTORE_STATUS_ATTRIBUTE = 'data-tl-restore-status';
export const FILL_STATUS_ATTRIBUTE = 'data-tl-fill-status';

export const PRICES_CACHE_FILE = '/tl-price-load/cache/price.json';

/* local storage */

export const MODULE_NAME = 'tl-price-load';
export const LOCAL_STORAGE_EXPIRE_ITEM = `${MODULE_NAME}:expire`;
export const LOCAL_STORAGE_PRICES_ITEM = `${MODULE_NAME}:prices`;
export const LOCAL_STORAGE_CACHE_VALUE = 2;
export const LOCAL_STORAGE_CACHE_MEASURE = 'hour';
export const LOCAL_STORAGE_EMPTY_CACHE = '';

/* lang */

export const SITE_LANG = document.documentElement.lang;
export const DEFAULT_DATE_LANG = 'en';
export const DEMO_DATE_FORMAT = 'DD.MM.YYYY';
export const PLACEHOLDER = {
    DateFormat: '{{DateFormat}}',
    MonthFormat: 'MMMM',
    MinLos: '{{minlos}}',
    MinLosEnd: '{{minlosDayEnd}}',
    MinLosDay: '{{minlosDay}}',
    Offer: '{{offer}}',
    Adults: '{{adults}}',
    AdultsEnd: '{{adultsEnd}}',
};
export const ROUND_OPTION = {
    No: false,                      // disable rounding
    RoundDown: 'RoundDown',         // rounding down
    RoundUp: 'RoundUp',             // rounding up
    RoundNearest: 'RoundNearest',   // rounding to the nearest integer
};
export const LANGUAGE_CONSTANT = {
    ru: {
        month: {
            'January': 'января',
            'February': 'февраля',
            'March': 'марта',
            'April': 'апреля',
            'May': 'мая',
            'June': 'июня',
            'July': 'июля',
            'August': 'августа',
            'September': 'сентября',
            'October': 'октября',
            'November': 'ноября',
            'December': 'декабря',
        },
        numberEnding: {
            one: '-го',
            twoToFour: '-х',
            fiveAndMore: '-и',
        },
        day: {
            one: 'дня',
            many: 'дней',
        }
    },
    en: {
        guest: {
            one: 'guest',
            many: 'guests',
        },
        day: {
            one: 'day',
            many: 'days',
        }
    }
};

/* link */

export const DEFAULT_BOOK_URL = `//${location.host}/bronirovanie/`;
export const LINK_OPTION_DELIMITER = ',';
export const LINK_OPTION_PARAM = {
    'hotel': 'hotel',
    'room': 'room-type',
    'date': 'date',
    'nights': 'nights',
    'adults': 'adults',
    'offer': 'tl-offer',
};