// see date formats here https://day.js.org/docs/en/display/format
// [h] - escaping characters
import {PLACEHOLDER, ROUND_OPTION} from "./const";

const SPACE = '&nb[s]p';

export const LANG_SETTING = {
    ru: {
        DateText: `Самая выгодная цена на ${PLACEHOLDER.DateFormat}`,
        DateFormat: `D${SPACE}${PLACEHOLDER.MonthFormat}${SPACE}YYYY`,
        MinLosText: `При бронировании от ${PLACEHOLDER.MinLos}${PLACEHOLDER.MinLosEnd} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `по тарифу «${PLACEHOLDER.Offer}»`,
        AdultsText: `на ${PLACEHOLDER.Adults}${PLACEHOLDER.AdultsEnd}`,
        UsePriceRound: ROUND_OPTION.RoundDown,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    en: {
        DateText: `Best rate available ${PLACEHOLDER.DateFormat}`,
        DateFormat: `${PLACEHOLDER.MonthFormat}${SPACE}D,${SPACE}YYYY`,
        MinLosText: `When booking from ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `on "${PLACEHOLDER.Offer}" special offer`,
        AdultsText: `for ${PLACEHOLDER.Adults} ${PLACEHOLDER.AdultsEnd}`,
        UsePriceRound: ROUND_OPTION.RoundDown,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    }
};